import React from 'react';
import { navigate } from 'gatsby';
import loadable from '@loadable/component';

import { isLoggedIn } from 'services/loginService';
import MainLayout from 'layouts/mainLayout';
import AuthPageTemplate from 'components/Pages/auth';
import CircularProgressOverlay from 'components/CircularProgressOverlay';

const AuthTemplate = props => {
  const { pageName } = props.pageContext;
  let PageContent = null;
  switch (pageName) {
    case 'Login':
      PageContent = loadable(() => import('./Login'));
      break;
    case 'Password':
      PageContent = loadable(() => import('./Password'));
      break;
    case 'Signup':
      PageContent = loadable(() => import('./Signup'));
      break;
    default:
      PageContent = loadable(() => import('./Login'));
      break;
  }

  /*
  if (!isLoggedIn() && typeof window !== 'undefined') {
    navigate('/login');
    return null;
  }
  */

  if (!PageContent) {
    return null;
  }

  return (
    <CircularProgressOverlay>
      <MainLayout pageName={pageName}>
        <AuthPageTemplate>
          <PageContent {...props} />
        </AuthPageTemplate>
      </MainLayout>
    </CircularProgressOverlay>
  );
};

export default AuthTemplate;
