import React from 'react';
import PropTypes from 'prop-types';
import { useStaticQuery, graphql } from 'gatsby';
import styled from 'styled-components';

import CircularProgressOverlay from 'components/CircularProgressOverlay';

const MainLayout = ({ children }) => {
  return (
    <AppContainer>
      <div className="body-container">
        <main>{children}</main>
      </div>
    </AppContainer>
  );
};

MainLayout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default MainLayout;

const AppContainer = styled.div``;
