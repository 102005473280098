import styled from 'styled-components';

export const AuthPageWrapper = styled.div`
  .hero-wrapper {
    position: relative;
    display: flex;
    flex-direction: column;

    .hero-footer,
    & > .divider {
      z-index: 7;
    }
  }

  .modal-content {
    box-shadow: rgb(255 255 255 / 30%) 0px 5rem 14rem 0px, rgb(0 0 0 / 60%) 0px 0.8rem 2.3rem, rgb(0 0 0 / 45%) 0px 0.2rem 0.3rem;
  }

  .main-content--container {
    width: 100%;
    max-width: 700px;
    margin: 0 auto;
  }

  .MuiOutlinedInput-root {
    border-radius: 0;
  }

  @media (max-width: 576px) {
    .main-content--container {
      .main-content--body {
        padding: 0 !important;
      }
    }
  }
`;